import React from "react"
import styled from "styled-components"
import { Container } from "../global"
import Logo from "../../images/logo.svg";

const Footer = () => (
  <FooterWrapper id="footer">
    <FooterColumnContainer>
    <BrandWrapper>
        <LogoContainer>
        <Logo />
            <Text>eelo</Text>
        </LogoContainer>
      <Subtitle>Copyright © 2020 Eelo, Inc. All rights reserved.</Subtitle>
    </BrandWrapper>

    </FooterColumnContainer>
  </FooterWrapper>
)


const FooterWrapper = styled.footer`
  background-color: white;
  margin: 80px 0 0;
  padding: 0 0 80px;
`

const Text = styled.div`
  font-family: ${props => props.theme.font.extrabold};
  ${props => props.theme.font_size.regular};
  color: ${props => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  text-decoration: none;
  outline: 0px;
`
const LogoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  max-height:50px;
  padding-bottom:0;
`

const BrandWrapper = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${props => props.theme.screen.sm}) {
  }
`
const Subtitle = styled(Container)`
  padding-top:8px;
  padding-left:2px;
  font-size:12px;
  font-weight: '300';
  @media (max-width: ${props => props.theme.screen.sm}) {
  }
`
const FooterColumnContainer = styled(Container)`
  display: grid;
  align-content:flex-start;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 32px;
  justify-content: start;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
`



export default Footer
