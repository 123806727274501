import React from "react"
import styled from "styled-components"
import DesktopStorm from "../../videos/storm169-compressed.mp4";
import MobileStorm from "../../videos/storm916-compressed.gif";
import AnchorLink from "react-anchor-link-smooth-scroll";
import localization from '../../static/localization';
import Logo from "../../images/logo.svg";

const Banner = ({isMobile}) => {
  const title = localization.banner_title.en
  const subtitle = localization.banner_subtitle.en
  const ctaText = localization.banner_cta.en

  return  (
    <>
        <SectionTextGroup>
        <h1>{title}</h1>
        <Subtitle>
          <h2>{subtitle}</h2><Logo />
        </Subtitle>
        <AnchorLink href="#landing"><CTAButton>{ctaText}</CTAButton></AnchorLink>
        </SectionTextGroup>
        {isMobile ?
          <GifWrapper>
          <img src={MobileStorm} alt=""/>
          </GifWrapper>
          :
        <VideoWrapper id="banner">
        <video playsInline autoPlay muted loop>
          <source src={DesktopStorm} type="video/mp4" />
        </video>
        </VideoWrapper>}
      </>
  )
}

export default Banner

const GifWrapper = styled.div`
  over-flow:hidden;
  -webkit-filter: brightness(70%);
  -moz-filter:  brightness(70%);
  -o-filter: brightness(70%);
  -ms-filter:  brightness(70%);
  filter:  brightness(70%);
  height:100vh;
  width:100%;
  > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  }
`
const Subtitle = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const SectionTextGroup = styled.div`
    text-decoration:none;
    position: absolute;
    top: 30%;
    width: 100%;
    text-align: center;
    margin: 0;
    z-index: 999;
  > AnchorLink {
    color:red;
  }
  h1 {
  padding-bottom:5px;
    color: rgba(228,228,228,0.9);
  },

  h2 {
  padding-bottom:20px;
  color: rgba(228,228,228,0.9);

  }

`
const CTAButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`
const VideoWrapper = styled.div`
    over-flow:hidden;
    -webkit-filter: brightness(40%);
    -moz-filter:  brightness(40%);
    -o-filter: brightness(40%);
    -ms-filter:  brightness(40%);
    filter:  brightness(40%);
    /* background: url("./sky.png") no-repeat center center fixed; */
  > video {
    width: 100%;
    height: 100vh;
    object-fit: cover;

  }

`
