import React, { Component } from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Scrollspy from "react-scrollspy"
import Logo from "../../../images/logo.svg";


import {
  Nav,
  Brand,
  StyledContainer,
} from "./style"



export default class Navigation extends Component {
  state = {
    hasScrolled: false,
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  handleScroll = event => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 32) {
      this.setState({ hasScrolled: true })
    } else {
      this.setState({ hasScrolled: false })
    }
  }




  render() {

    return (
      <Nav {...this.props} scrolled={this.state.hasScrolled}>
        <StyledContainer>
          <Brand>
            <Scrollspy offset={-64} item={["landing"]} currentClassName="active">
              <div id="logo">
              <Logo />
              <AnchorLink href="#landing">
                eelo
              </AnchorLink>
              </div>
            </Scrollspy>
          </Brand>
      </StyledContainer>
      </Nav>
    )
  }
}
