import React,{useEffect,useState} from "react"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import Banner from "../components/sections/banner"
import HowItWorks from "../components/sections/howItWorks"
import Landing from "../components/sections/landing"
import Footer from "../components/sections/footer"
import theme from "../styles/theme"

const IndexPage = () => {
  const [isMobile,setIsMobile]= useState(null)
  useEffect(()=>{
    const _isMobile = window.innerWidth <= parseInt(theme.screen.xs.substring(0, theme.screen.xs.length - 2));
    setIsMobile(_isMobile)
  },[])
  return (
    <Layout>
      <SEO title="eelo" />
      <Navigation />
      {(isMobile !== null) &&
      <>
      <Banner isMobile={isMobile}/>
      <Landing />
  
      <Footer />
      </>}
    </Layout>
  )
};


export default IndexPage
