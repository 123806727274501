// Theme

const localization = {
  banner_title: {
    en: `Building a community for gamers`,
  },
  banner_subtitle: {
    en:`with eelo`,

  },
  banner_cta: {
    en:`Early Access`,
  },
  landing_title: {
    en: `LFG (looking for group) made easy`,

  },
  landing_subtitle: {
    en:`Sign up to get beta access.`,
  },


}

export default localization
